import type { InjectionKey, Ref } from 'vue'
import { computed, inject, onMounted, provide, readonly } from 'vue'
import { createError, useState } from '#app'
import { useRpQuery } from '~/composables/graphql'
import { useQuery } from '@tanstack/vue-query'
import { getFragmentData, graphql } from '~/resources/graphql'
import { useLayoutMode } from '~/composables/use-layout-mode'
import type { ResultOf } from '@graphql-typed-document-node/core'

export const ActiveFacilityFragment = graphql(`
    fragment ActiveFacilityFragment on Facility {
        id
        shortName
        longName
        slug
        active
        timezone
        title
        country
        branding {
            styleString
            logo
            primaryColor
        }
        organization {
            code
            shortName
            longName
            logo
            topBarLink
            topBarText
            topBarColor
            contactUsLinkText
            onlineGuestId
        }
    }
`)

export type ActiveFacility = ResultOf<typeof ActiveFacilityFragment>

export const activeFacilityInjectionKey: InjectionKey<Ref<ActiveFacility>> =
    Symbol('activeFacilityInjectionKey')
export function useActiveFacility(): Ref<ActiveFacility> {
    const activeFacility = inject(activeFacilityInjectionKey)
    if (activeFacility === undefined) {
        throw new Error('No Active Facility')
    }

    return activeFacility
}

export function provideActiveFacility(activeFacility: Ref<ActiveFacility>) {
    const { isLightbox } = useLayoutMode()
    provide(activeFacilityInjectionKey, readonly(activeFacility))
    onMounted(() => {
        if (isLightbox.value) {
            parent.postMessage(
                'RPHQ::1::' +
                    JSON.stringify({
                        key: 'lightbox-configure',
                        backgroundColor:
                            activeFacility.value.branding.primaryColor
                    }),
                '*'
            )
        }
    })
}

export async function provideActiveFacilityFromSlug() {
    const facilitySlug = useState<string>('facilityParamSlug')
    const query = useRpQuery({ orgLevel: true })
    const { data, suspense } = useQuery({
        queryKey: ['ActiveFacilityWrapperQuery', facilitySlug],
        placeholderData: previousData => previousData,
        queryFn: () =>
            query(
                graphql(/* GraphQL */ `
                    query ActiveFacilityWrapperQuery($slug: String!) {
                        facility(slug: $slug) {
                            ...ActiveFacilityFragment
                        }
                    }
                `),
                {
                    slug: facilitySlug.value
                }
            )
    })

    const activeFacility = computed<ActiveFacility>(() => {
        const result = data.value?.facility
        if (result === undefined || result === null) {
            throw createError({
                statusCode: 404,
                statusMessage: 'Page Not Found'
            })
        }

        return getFragmentData(ActiveFacilityFragment, result)
    })
    provideActiveFacility(activeFacility)
    return await suspense().then(() => {
        return readonly(activeFacility)
    })
}
