import { addIntegration, browserTracingIntegration, init } from '@sentry/vue'
import { defineNuxtPlugin } from '#app'
import { useRouter } from 'vue-router'
import {
    captureConsoleIntegration,
    contextLinesIntegration
} from '@sentry/integrations'

function getSentryIntegrations() {
    // don't load on server
    if (!process.client) return []

    const router = useRouter()

    return [
        browserTracingIntegration({
            router,
            routeLabel: 'path'
        }),
        captureConsoleIntegration({
            levels: ['error']
        }),
        contextLinesIntegration()
    ]
}

export async function lazyLoadIntegrations() {
    // don't load on server
    if (!process.client) return []

    const { replayIntegration } = await import('@sentry/vue')
    addIntegration(
        replayIntegration({
            // Additional SDK configuration goes in here, for example:
            // maskAllText: true,
            // blockAllMedia: true
        })
    )
}

export default defineNuxtPlugin({
    name: 'sentry',
    setup(nuxtApp) {
        const runtimeConfig = nuxtApp.$config

        init({
            app: nuxtApp.vueApp,
            dsn: runtimeConfig.public.sentry.dsn,
            environment: runtimeConfig.public.sentry.environment,
            integrations: getSentryIntegrations(),

            // Configure this whole part as you need it!

            tracesSampleRate: import.meta.env.DEV ? 0.2 : 0.0002, // Change in prod

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                'https://ewtest.rphq.com',
                'https://portal.sfrunco.com',
                'https://portal.edgeworksclimbing.com'
            ],

            // This sets the sample rate to be 10%. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: import.meta.env.DEV ? 0 : 0.1,

            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: import.meta.env.DEV ? 0 : 1.0
        })

        lazyLoadIntegrations()
    }
})
