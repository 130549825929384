import { defineNuxtRouteMiddleware, useState } from '#app'

// https://stackoverflow.com/questions/76127659/route-params-are-undefined-in-layouts-components-in-nuxt-3
// Basically Nuxt will render the layout using the old route context, so we do not
// get access to the new facility id in the route if we come from the profile page.
// Here we slam it into global state and use that instead.
export default defineNuxtRouteMiddleware(to => {
    // Only set the new value if we have something valuable
    // Otherwise it breaks what is currently rendering
    if (to.params.facility !== undefined) {
        useState('facilityParamSlug').value = to.params.facility
    }
})
