import { defineNuxtPlugin } from '#imports'
import { graphqlClientInjectionKey } from '~/composables/graphql'
import { getRequestHost } from 'h3'
import { useRequestHeaders } from '#app'
import { AwesomeGraphQLClient } from 'awesome-graphql-client'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { print } from 'graphql/language/printer'

export default defineNuxtPlugin(nuxt => {
    let client: AwesomeGraphQLClient<TypedDocumentNode>
    if (process.server && nuxt.ssrContext) {
        const headers = useRequestHeaders()
        const event = nuxt.ssrContext.event
        const endpoint =
            'http://' +
            getRequestHost(event).replace(/(:8080|:3000)/, ':8000') +
            '/graphql-public'
        client = new AwesomeGraphQLClient({
            endpoint,
            fetchOptions: { headers },
            formatQuery: (query: TypedDocumentNode) => print(query)
        })
    } else {
        client = new AwesomeGraphQLClient({
            endpoint: '/graphql-public',
            formatQuery: (query: TypedDocumentNode) => print(query)
        })
    }

    nuxt.vueApp.provide(graphqlClientInjectionKey, client)
})
