import { useDialog } from 'primevue/usedialog'
import AppAlert from '~/components/Modals/AppAlert.vue'
import { h, markRaw } from 'vue'
import AppAlertFooter from '~/components/Modals/AppAlertFooter.vue'

export function useShowAlert(): (
    header: string,
    body: string
) => Promise<void> {
    const dialog = useDialog()

    return (header: string, body: string) => {
        return new Promise(resolve => {
            const dialogRef = dialog.open(() => h(AppAlert, { body }), {
                props: {
                    header,
                    style: {
                        width: '40rem'
                    },
                    modal: true
                },
                templates: {
                    footer: markRaw(
                        h(AppAlertFooter, { onClose: () => dialogRef.close() })
                    )
                },
                onClose: () => {
                    resolve()
                }
            })
        })
    }
}
