<template>
    <p class="line-height-3 m-0">
        {{ body }}
    </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'AppAlert',

    props: {
        body: {
            type: String,
            required: true
        }
    }
})
</script>
