<template>
    <template v-if="error.statusCode === 404">
        <svg
            viewBox="0 0 960 540"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            class="min-w-screen fixed left-0 min-h-screen"
            :style="{ bottom: '-10rem' }"
            preserveAspectRatio="none"
        >
            <rect
                x="0"
                y="0"
                width="960"
                height="540"
                fill="#eff3f8"
            ></rect>
            <path
                d="M0 331L26.7 321C53.3 311 106.7 291 160 291C213.3 291 266.7 311 320 329.5C373.3 348 426.7 365 480 373.2C533.3 381.3 586.7 380.7 640 373.8C693.3 367 746.7 354 800 341.2C853.3 328.3 906.7 315.7 933.3 309.3L960 303L960 541L933.3 541C906.7 541 853.3 541 800 541C746.7 541 693.3 541 640 541C586.7 541 533.3 541 480 541C426.7 541 373.3 541 320 541C266.7 541 213.3 541 160 541C106.7 541 53.3 541 26.7 541L0 541Z"
                fill="var(--orange-500)"
                strokeLinecap="round"
                strokeLinejoin="miter"
            ></path>
        </svg>
        <div class="flex min-h-screen items-center justify-center px-5">
            <div class="z-10 text-center">
                <div class="mb-4 text-8xl font-bold text-gray-900">Oops!</div>
                <p
                    class="line-height-3 mb-5 mt-0 text-xl font-medium text-gray-700"
                >
                    Page Not Found
                </p>
                <prime-button
                    severity="danger"
                    raised
                    class="mt-4"
                    @click="goHome"
                >
                    Back to Home
                </prime-button>
            </div>
        </div>
    </template>
    <template v-else>
        <svg
            viewBox="0 0 960 540"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            class="min-w-screen fixed left-0 min-h-screen"
            :style="{ bottom: '-10rem' }"
            preserveAspectRatio="none"
        >
            <rect
                x="0"
                y="0"
                width="960"
                height="540"
                fill="#eff3f8"
            ></rect>
            <path
                d="M0 331L26.7 321C53.3 311 106.7 291 160 291C213.3 291 266.7 311 320 329.5C373.3 348 426.7 365 480 373.2C533.3 381.3 586.7 380.7 640 373.8C693.3 367 746.7 354 800 341.2C853.3 328.3 906.7 315.7 933.3 309.3L960 303L960 541L933.3 541C906.7 541 853.3 541 800 541C746.7 541 693.3 541 640 541C586.7 541 533.3 541 480 541C426.7 541 373.3 541 320 541C266.7 541 213.3 541 160 541C106.7 541 53.3 541 26.7 541L0 541Z"
                fill="var(--orange-500)"
                strokeLinecap="round"
                strokeLinejoin="miter"
            ></path>
        </svg>
        <div class="flex min-h-screen items-center justify-center px-5">
            <div class="z-10 text-center">
                <div class="mb-4 text-8xl font-bold text-gray-900">ERROR</div>
                <p
                    class="line-height-3 mb-5 mt-0 text-xl font-medium text-gray-700"
                >
                    Something went wrong!
                </p>
                <prime-button
                    severity="danger"
                    raised
                    class="mt-4"
                    @click="goHome"
                >
                    Back to Home
                </prime-button>

                <div
                    v-if="isDev"
                    class="mt-4 border border-gray-300 bg-gray-100 p-4 text-left"
                >
                    <div v-html="error.message" />
                    <div v-html="error.stack" />
                </div>
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
import PrimeButton from 'primevue/button'
import { activeFacilityInjectionKey } from '~/composables/use-active-facility'
import { inject } from 'vue'
import { useRouteLink } from '~/composables/routing'
import { clearError } from '#app/composables/error'

defineProps<{
    error: {
        url: string
        statusCode: number
        statusMessage: string
        message: string
        description: string
        data: any
    }
}>()

const isDev = import.meta.env.DEV

// Raw in inject to avoid no active facility error
const activeFacility = inject(activeFacilityInjectionKey, undefined)
const { orgHomeLink, facilityHomeLink } = useRouteLink()
function goHome() {
    clearError({
        redirect:
            activeFacility?.value === undefined
                ? orgHomeLink()
                : facilityHomeLink(activeFacility.value.slug)
    })
}
</script>
