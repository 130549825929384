import revive_payload_client_4sVQNw7RlN from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _01_error_handler_fv2y2mLM3P from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/plugins/01.error-handler.ts";
import _02_sentry_zFVtbleeds from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/plugins/02.sentry.ts";
import graphql_4fQqdKCzIn from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/plugins/graphql.ts";
import primevue_7rYYRZQLyx from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/plugins/primevue.ts";
import vue_query_wrmMkNpEpe from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/plugins/vue-query.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  _01_error_handler_fv2y2mLM3P,
  _02_sentry_zFVtbleeds,
  graphql_4fQqdKCzIn,
  primevue_7rYYRZQLyx,
  vue_query_wrmMkNpEpe
]