<template>
    <prime-button
        label="Ok"
        icon="pi pi-check"
        class="p-button-outlined"
        @click="$emit('close')"
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Button from 'primevue/button'

export default defineComponent({
    name: 'AppAlertFooter',

    components: { PrimeButton: Button },

    emits: {
        close: () => true
    }
})
</script>
