import validate from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/nuxt/dist/pages/runtime/validate.js";
import set_45facility_45container_45global from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/middleware/set-facility-container.global.ts";
import set_45storefront_45container_45global from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/middleware/set-storefront-container.global.ts";
import manifest_45route_45rule from "/Users/ted/workspace/rphq/rphq-redpoint-api-v1/cpx/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  set_45facility_45container_45global,
  set_45storefront_45container_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}