import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import DialogService from 'primevue/dialogservice'
import ConfirmationService from 'primevue/confirmationservice'
import Ripple from 'primevue/ripple'
import { defineNuxtPlugin } from '#imports'
//import Tailwind from 'primevue/passthrough/tailwind'
import Tailwind from '~/support/rphq-tailwind'
import { usePassThrough } from 'primevue/passthrough'

export const TRANSITIONS = {
    overlay: {
        // Change from scale-75
        enterFromClass: 'opacity-0 scale-y-75',
        enterActiveClass:
            'transition-transform transition-opacity duration-[120] ease-[cubic-bezier(0,0,0.2,1)]',
        leaveActiveClass: 'transition-opacity duration-[100] ease-linear',
        leaveToClass: 'opacity-0'
    }
}

const CustomTailwind = usePassThrough(
    Tailwind,
    {
        card: {
            root: {
                class: [
                    'bg-surface-0 dark:bg-surface-900',
                    'text-surface-700 dark:text-surface-0/80'
                ]
            },
            body: {
                class: ''
            },
            title: {
                class: 'text-2xl font-medium mb-3'
            }
        },
        // Override dark mode to be slightly lighter because we use 800 as background color
        skeleton: {
            root: ({ props }) => ({
                class: [
                    'overflow-hidden',
                    '!mb-2',
                    'bg-gray-300 dark:bg-gray-700',
                    'after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:content after:w-full after:h-full after:bg-blue-400 after:left-full after:transform after:translate-x-full after:z-10 after:bg-gradient-to-r after:from-transparent after:via-white after:to-transparent animate-pulse',
                    {
                        'rounded-md': props.shape !== 'circle',
                        'rounded-full': props.shape == 'circle'
                    }
                ]
            })
        },
        // Toast uses left: 50%, but does not translate x to actually center item
        toast: {
            root: ({ props }) => ({
                class: [
                    'w-96',
                    'opacity-90',
                    props.position === 'center' ||
                    props.position === 'top-center' ||
                    props.position === 'bottom-center'
                        ? 'transform -translate-x-1/2'
                        : ''
                ]
            })
        },
        // Button with loading does not have padding
        button: {
            root: ({ props, context }) => ({
                class: [
                    'items-center cursor-pointer inline-flex overflow-hidden relative select-none text-center align-bottom',
                    'transition duration-200 ease-in-out',
                    'focus:outline-none focus:outline-offset-0',
                    {
                        'text-button dark:text-gray-900 bg-primary-500 dark:bg-primary-400 border border-primary-500 dark:border-primary-400 hover:bg-primary-600 dark:hover:bg-primary-500 hover:border-primary-600 dark:hover:border-primary-500 focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                            !props.link &&
                            props.severity === null &&
                            !props.text &&
                            !props.outlined &&
                            !props.plain,
                        'text-primary-600 bg-transparent border-transparent focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                            props.link && props.severity !== 'danger',
                        'text-red-600 bg-transparent border-transparent focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                            props.link && props.severity === 'danger'
                    },
                    {
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(176,185,198,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(203,213,225,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                            props.severity === 'secondary',
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(136,234,172,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(134,239,172,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                            props.severity === 'success',
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(157,193,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(147,197,253,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                            props.severity === 'info',
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(250,207,133,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(252,211,77,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                            props.severity === 'warning',
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(212,170,251,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(216,180,254,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                            props.severity === 'help',
                        'focus:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_4px_rgba(247,162,162,1),0_1px_2px_0_rgba(0,0,0,1)] dark:focus:shadow-[0_0_0_2px_rgba(28,33,39,1),0_0_0_4px_rgba(252,165,165,0.7),0_1px_2px_0_rgba(0,0,0,0)]':
                            props.severity === 'danger'
                    },
                    {
                        'text-white dark:text-gray-900 bg-gray-500 dark:bg-gray-400 border border-gray-500 dark:border-gray-400 hover:bg-gray-600 dark:hover:bg-gray-500 hover:border-gray-600 dark:hover:border-gray-500':
                            props.severity === 'secondary' &&
                            !props.text &&
                            !props.outlined &&
                            !props.plain,
                        'text-white dark:text-gray-900 bg-green-500 dark:bg-green-400 border border-green-500 dark:border-green-400 hover:bg-green-600 dark:hover:bg-green-500 hover:border-green-600 dark:hover:border-green-500':
                            props.severity === 'success' &&
                            !props.text &&
                            !props.outlined &&
                            !props.plain,
                        'text-white dark:text-gray-900 dark:bg-primary-400 bg-primary-500 dark:bg-primary-400 border border-primary-500 dark:border-primary-400 hover:bg-primary-600 hover:border-primary-600 dark:hover:bg-primary-500 dark:hover:border-primary-500':
                            props.severity === 'info' &&
                            !props.text &&
                            !props.outlined &&
                            !props.plain,
                        'text-white dark:text-gray-900 bg-orange-500 dark:bg-orange-400 border border-orange-500 dark:border-orange-400 hover:bg-orange-600 dark:hover:bg-orange-500 hover:border-orange-600 dark:hover:border-orange-500':
                            props.severity === 'warning' &&
                            !props.text &&
                            !props.outlined &&
                            !props.plain,
                        'text-white dark:text-gray-900 bg-purple-500 dark:bg-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-600 dark:hover:bg-purple-500 hover:border-purple-600 dark:hover:border-purple-500':
                            props.severity === 'help' &&
                            !props.text &&
                            !props.outlined &&
                            !props.plain,
                        'text-white dark:text-gray-900 bg-red-500 dark:bg-red-400 border border-red-500 dark:border-red-400 hover:bg-red-600 dark:hover:bg-red-500 hover:border-red-600 dark:hover:border-red-500':
                            props.severity === 'danger' &&
                            !props.text &&
                            !props.outlined &&
                            !props.plain &&
                            !props.link
                    },
                    { 'shadow-lg': props.raised },
                    { 'rounded-full': props.rounded },
                    {
                        'bg-transparent border-transparent':
                            props.text && !props.plain,
                        'text-primary-500 dark:text-primary-400 hover:bg-primary-300/20':
                            props.text &&
                            (props.severity === null ||
                                props.severity === 'info') &&
                            !props.plain,
                        'text-gray-500 dark:text-gray-400 hover:bg-gray-300/20':
                            props.text &&
                            props.severity === 'secondary' &&
                            !props.plain,
                        'text-green-500 dark:text-green-400 hover:bg-green-300/20':
                            props.text &&
                            props.severity === 'success' &&
                            !props.plain,
                        'text-orange-500 dark:text-orange-400 hover:bg-orange-300/20':
                            props.text &&
                            props.severity === 'warning' &&
                            !props.plain,
                        'text-purple-500 dark:text-purple-400 hover:bg-purple-300/20':
                            props.text &&
                            props.severity === 'help' &&
                            !props.plain,
                        'text-red-500 dark:text-red-400 hover:bg-red-300/20':
                            props.text &&
                            props.severity === 'danger' &&
                            !props.plain
                    },
                    { 'shadow-lg': props.raised && props.text },
                    {
                        'text-gray-500 hover:bg-gray-300/20':
                            props.plain && props.text,
                        'text-gray-500 border border-gray-500 hover:bg-gray-300/20':
                            props.plain && props.outlined,
                        'text-white bg-gray-500 border border-gray-500 hover:bg-gray-600 hover:border-gray-600':
                            props.plain && !props.outlined && !props.text
                    },
                    {
                        'bg-transparent border': props.outlined && !props.plain,
                        'text-primary-500 dark:text-primary-400 border border-primary-500 dark:border-primary-400 hover:bg-primary-300/20':
                            props.outlined &&
                            (props.severity === null ||
                                props.severity === 'info') &&
                            !props.plain,
                        'text-gray-500 dark:text-gray-400 border border-gray-500 dark:border-gray-400 hover:bg-gray-300/20':
                            props.outlined &&
                            props.severity === 'secondary' &&
                            !props.plain,
                        'text-green-500 dark:text-green-400 border border-green-500 dark:border-green-400 hover:bg-green-300/20':
                            props.outlined &&
                            props.severity === 'success' &&
                            !props.plain,
                        'text-orange-500 dark:text-orange-400 border border-orange-500 dark:border-orange-400 hover:bg-orange-300/20':
                            props.outlined &&
                            props.severity === 'warning' &&
                            !props.plain,
                        'text-purple-500 dark:text-purple-400 border border-purple-500 dark:border-purple-400 hover:bg-purple-300/20':
                            props.outlined &&
                            props.severity === 'help' &&
                            !props.plain,
                        'text-red-500 dark:text-red-400 border border-red-500 dark:border-red-400 hover:bg-red-300/20':
                            props.outlined &&
                            props.severity === 'danger' &&
                            !props.plain
                    },
                    {
                        'px-4 py-3 text-base': props.size === null,
                        'text-xs py-2 px-3': props.size === 'small',
                        'text-xl py-3 px-4': props.size === 'large'
                    },
                    {
                        'flex-column':
                            props.iconPos == 'top' || props.iconPos == 'bottom'
                    },
                    {
                        'opacity-60 pointer-events-none cursor-default':
                            context.disabled
                    }
                ]
            }),
            label: ({ props }) => ({
                class: [
                    'flex-1',
                    'duration-200',
                    'font-bold',
                    {
                        'underline hover:no-underline': props.link
                    },
                    { 'invisible w-0': props.label == null },
                    props.loading && props.label !== null ? 'ml-2' : ''
                ]
            }),
            loadingicon: ({ props }) => ({
                class: [
                    'h-4 w-4',
                    'mx-0',
                    {
                        'mr-2': props.iconPos == 'left' && props.label != null,
                        'ml-2 order-1':
                            props.iconPos == 'right' && props.label != null,
                        'mb-2': props.iconPos == 'top' && props.label != null,
                        'mt-2': props.iconPos == 'bottom' && props.label != null
                    },
                    'animate-spin'
                ]
            })
        },
        autocomplete: {
            transition: TRANSITIONS.overlay
        },
        dropdown: {
            transition: TRANSITIONS.overlay,
            root: ({ props }) => ({
                class: [
                    'cursor-pointer inline-flex relative select-none',
                    'bg-white border border-gray-400 transition-colors duration-200 ease-in-out',
                    'dark:bg-gray-900 dark:border-blue-900/40 dark:hover:border-blue-300',
                    'w-full md:w-56',
                    'hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
                    {
                        'opacity-60 select-none pointer-events-none cursor-default':
                            props.disabled
                    }
                ]
            })
        },
        inputtext: {
            root: ({ props, context }) => ({
                class: [
                    'm-0',
                    'font-sans text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 border border-gray-300 dark:border-primary-900/40 transition-colors duration-200 appearance-none rounded-none',
                    {
                        'hover:border-primary-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]':
                            !context.disabled,
                        'opacity-60 select-none pointer-events-none cursor-default':
                            context.disabled
                    },
                    {
                        'text-lg px-4 py-4': props.size == 'large',
                        'text-[16px] px-2 py-2': props.size == 'small',
                        'p-3 text-[16px]': props.size == null
                    }
                ]
            })
        },
        dialog: {
            root: ({ state }) => ({
                class: [
                    'rounded-lg shadow-lg border-0',
                    'max-h-90 transform scale-100',
                    'm-0 w-[50vw]',
                    'dark:border dark:border-primary-900/40',
                    {
                        'transition-none transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
                            state.maximized
                    }
                ]
            }),
            header: {
                class: [
                    'flex items-center justify-between shrink-0',
                    'bg-white text-gray-700 border-t-0  rounded-tl-lg rounded-tr-lg p-6',
                    'dark:bg-gray-900  dark:text-white/80',
                    'text-xl font-medium'
                ]
            },
            headerTitle: {
                class: 'font-bold text-lg'
            },
            headerIcons: {
                class: 'flex items-center'
            },
            closeButton: {
                class: [
                    'flex items-center justify-center overflow-hidden relative',
                    'w-8 h-8 text-gray-500 border-0 bg-transparent rounded-full transition duration-200 ease-in-out mr-2 last:mr-0',
                    'hover:text-gray-700 hover:border-transparent hover:bg-gray-200',
                    'focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)]', // focus
                    'dark:hover:text-white/80 dark:hover:border-transparent dark:hover:bg-gray-800/80 dark:focus:shadow-[inset_0_0_0_0.2rem_rgba(147,197,253,0.5)]'
                ]
            },
            closeButtonIcon: {
                class: 'w-4 h-4 inline-block'
            },
            content: ({ state, instance }) => ({
                class: [
                    'overflow-y-auto',
                    'bg-white text-gray-700 px-6 pb-8 pt-0',
                    ,
                    'dark:bg-gray-900  dark:text-white/80',
                    {
                        grow: state.maximized
                    },
                    {
                        'rounded-bl-lg rounded-br-lg': !instance.$slots.footer
                    }
                ]
            }),
            footer: {
                class: [
                    'flex gap-2 shrink-0 justify-end align-center',
                    'border-t-0 bg-white text-gray-700 px-6 pb-6 text-right rounded-b-lg',
                    'dark:bg-gray-900  dark:text-white/80'
                ]
            },
            mask: ({ props }) => ({
                class: [
                    'transition duration-200',
                    { 'bg-black/40': props.modal }
                ]
            }),
            transition: ({ props }) => {
                return props.position === 'top'
                    ? {
                          enterFromClass:
                              'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0',
                          enterActiveClass:
                              'transition-all duration-200 ease-out',
                          leaveActiveClass:
                              'transition-all duration-200 ease-out',
                          leaveToClass:
                              'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0'
                      }
                    : props.position === 'bottom'
                    ? {
                          enterFromClass: 'opacity-0 scale-75 translate-y-full',
                          enterActiveClass:
                              'transition-all duration-200 ease-out',
                          leaveActiveClass:
                              'transition-all duration-200 ease-out',
                          leaveToClass:
                              'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0'
                      }
                    : props.position === 'left' ||
                      props.position === 'topleft' ||
                      props.position === 'bottomleft'
                    ? {
                          enterFromClass:
                              'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0',
                          enterActiveClass:
                              'transition-all duration-200 ease-out',
                          leaveActiveClass:
                              'transition-all duration-200 ease-out',
                          leaveToClass:
                              'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0'
                      }
                    : props.position === 'right' ||
                      props.position === 'topright' ||
                      props.position === 'bottomright'
                    ? {
                          enterFromClass:
                              'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0',
                          enterActiveClass:
                              'transition-all duration-200 ease-out',
                          leaveActiveClass:
                              'transition-all duration-200 ease-out',
                          leaveToClass:
                              'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0'
                      }
                    : {
                          enterFromClass: 'opacity-0 scale-75',
                          enterActiveClass:
                              'transition-all duration-200 ease-out',
                          leaveActiveClass:
                              'transition-all duration-200 ease-out',
                          leaveToClass: 'opacity-0 scale-75'
                      }
            }
        },
        // Copied from wind preset of primvue-tailwind
        datatable: {
            root: ({ props }) => ({
                class: [
                    'relative',
                    // Flex & Alignment
                    {
                        'flex flex-col':
                            props.scrollable && props.scrollHeight === 'flex'
                    },
                    // Size
                    {
                        'h-full':
                            props.scrollable && props.scrollHeight === 'flex'
                    },
                    // Shape
                    'border-spacing-0 border-separate'
                ]
            }),
            loadingoverlay: {
                class: [
                    'absolute',
                    'top-0 left-0',
                    'z-20',
                    'flex items-center justify-center',
                    'w-full h-full',
                    'bg-gray-100/40 dark:bg-gray-800/40',
                    'transition duration-200'
                ]
            },
            loadingicon: {
                class: 'w-8 h-8 animate-spin'
            },
            wrapper: ({ props }) => ({
                class: [
                    {
                        relative: props.scrollable,
                        'flex flex-col grow':
                            props.scrollable && props.scrollHeight === 'flex'
                    },
                    // Size
                    {
                        'h-full':
                            props.scrollable && props.scrollHeight === 'flex'
                    }
                ]
            }),
            header: ({ props }) => ({
                class: [
                    'font-semibold',
                    // Shape
                    props.showGridlines ? 'border-b' : 'border-b border-x-0',
                    // Spacing
                    'py-3.5 px-3',
                    // Color
                    'bg-gray-0 dark:bg-gray-800',
                    'border-gray-300 dark:border-gray-600',
                    'text-gray-700 dark:text-white/80'
                ]
            }),
            table: {
                class: 'w-full border-spacing-0 border-separate'
            },
            thead: ({ context }) => ({
                class: [
                    {
                        'top-0 z-40 sticky': context.scrollable
                    }
                ]
            }),
            tbody: ({ instance, context }) => ({
                class: [
                    'border-t border-gray-300 dark:border-gray-600',
                    {
                        'sticky z-20 font-semibold':
                            instance.frozenRow && context.scrollable
                    }
                ]
            }),
            tfoot: ({ context }) => ({
                class: [
                    {
                        'bottom-0 z-0': context.scrollable
                    }
                ]
            }),
            footer: {
                class: [
                    'font-semibold',
                    'border-t-0 border-t border-x-0',
                    'p-4',
                    'bg-gray-0 dark:bg-gray-800',
                    'border-gray-200 dark:border-gray-700',
                    'text-gray-700 dark:text-white/80'
                ]
            },
            column: {
                headercell: ({ context, props }) => ({
                    class: [
                        'font-semibold',
                        'text-sm',
                        // Position
                        {
                            'sticky z-20 border-b':
                                props.frozen || props.frozen === ''
                        },
                        { relative: context.resizable },
                        // Alignment
                        'text-left',
                        // Shape
                        {
                            'border-r last:border-r-0':
                                context == null ? void 0 : context.showGridlines
                        },
                        'border-0 border-b border-solid',
                        // Spacing
                        (context == null ? void 0 : context.size) === 'small'
                            ? 'py-2.5 px-2'
                            : (context == null ? void 0 : context.size) ===
                              'large'
                            ? 'py-5 px-4'
                            : 'py-3.5 px-3',
                        // Color
                        (props.sortable === '' || props.sortable) &&
                        context.sorted
                            ? 'text-primary-500'
                            : 'bg-gray-0 text-gray-700',
                        (props.sortable === '' || props.sortable) &&
                        context.sorted
                            ? 'dark:text-primary-400'
                            : 'dark:text-white/80 dark:bg-gray-800',
                        'border-gray-200 dark:border-gray-700 ',
                        // States
                        'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
                        // Transition
                        {
                            'transition duration-200':
                                props.sortable === '' || props.sortable
                        },
                        // Misc
                        {
                            'cursor-pointer':
                                props.sortable === '' || props.sortable
                        },
                        {
                            'overflow-hidden space-nowrap bg-clip-padding':
                                context.resizable
                        }
                    ]
                }),
                headercontent: {
                    class: 'flex items-center'
                },
                sort: ({ context }) => ({
                    class: [
                        context.sorted ? 'text-primary-500' : 'text-gray-700',
                        context.sorted
                            ? 'dark:text-primary-400'
                            : 'dark:text-white/80'
                    ]
                }),
                bodycell: ({ props, context, state, parent }) => ({
                    class: [
                        //Position
                        {
                            'sticky box-border border-b':
                                parent.instance.frozenRow
                        },
                        {
                            'sticky box-border border-b':
                                props.frozen || props.frozen === ''
                        },
                        'text-sm',
                        // Alignment
                        'text-left',
                        'border-0 border-b border-solid',
                        {
                            'last:border-r-0 border-r border-b':
                                context == null ? void 0 : context.showGridlines
                        },
                        {
                            'bg-gray-0 dark:bg-gray-800':
                                parent.instance.frozenRow ||
                                props.frozen ||
                                props.frozen === ''
                        },
                        // Spacing
                        {
                            'py-2.5 px-2':
                                (context == null ? void 0 : context.size) ===
                                    'small' && !state.d_editing
                        },
                        {
                            'py-5 px-4':
                                (context == null ? void 0 : context.size) ===
                                    'large' && !state.d_editing
                        },
                        {
                            'py-3.5 px-3':
                                (context == null ? void 0 : context.size) !==
                                    'large' &&
                                (context == null ? void 0 : context.size) !==
                                    'small' &&
                                !state.d_editing
                        },
                        { 'py-[0.6rem] px-2': state.d_editing },
                        // Color
                        'border-gray-200 dark:border-gray-700',
                        // Misc
                        'space-nowrap'
                    ]
                }),
                footercell: ({ context }) => ({
                    class: [
                        // Font
                        'font-bold',
                        // Alignment
                        'text-left',
                        // Shape
                        {
                            'border-r last:border-r-0':
                                context == null ? void 0 : context.showGridlines
                        },
                        'border-0 border-t border-solid',
                        // Spacing
                        (context == null ? void 0 : context.size) === 'small'
                            ? 'p-2'
                            : (context == null ? void 0 : context.size) ===
                              'large'
                            ? 'p-5'
                            : 'p-4',
                        // Color
                        'border-gray-200 dark:border-gray-700',
                        'text-gray-700 dark:text-white/80',
                        'bg-gray-0 dark:bg-gray-800'
                    ]
                }),
                sorticon: {
                    class: 'ml-2'
                },
                sortbadge: {
                    class: [
                        'flex items-center justify-center align-middle',
                        'rounded-full',
                        'w-[1.143rem] leading-[1.143rem]',
                        'ml-2',
                        'text-primary-700 dark:text-white',
                        'bg-primary-50 dark:bg-primary-400/30'
                    ]
                },
                columnfilter: {
                    class: 'inline-flex items-center ml-auto'
                },
                filteroverlay: {
                    class: [
                        'absolute top-0 left-0',
                        'mt-2',
                        'border-0',
                        'rounded-md',
                        'shadow-md',
                        'min-w-[12.5rem]',
                        'bg-gray-0 dark:bg-gray-800',
                        'text-gray-800 dark:text-white/80',
                        'ring-1 ring-inset ring-gray-300 dark:ring-gray-700'
                    ]
                },
                filtermatchmodedropdown: {
                    root: ({ state }) => ({
                        class: [
                            // Display and Position
                            'flex',
                            'relative',
                            // Spacing
                            'mb-2',
                            // Shape
                            'w-full',
                            'rounded-md',
                            'shadow-sm',
                            // Color and Background
                            'bg-gray-0 dark:bg-gray-900',
                            {
                                'ring-1 ring-inset ring-gray-300 dark:ring-gray-700':
                                    !state.focused
                            },
                            // Transitions
                            'transition-all',
                            'duration-200',
                            // States
                            {
                                'outline-none outline-offset-0 ring-2 ring-primary-500 dark:ring-primary-400':
                                    state.focused
                            },
                            // Misc
                            'cursor-default',
                            'select-none'
                        ]
                    }),
                    input: ({ props }) => ({
                        class: [
                            //Font
                            'font-sans',
                            'leading-6',
                            'sm:text-sm',
                            // Display
                            'block',
                            'flex-auto',
                            // Color and Background
                            'bg-transparent',
                            'border-0',
                            {
                                'text-gray-800 dark:text-white/80':
                                    props.modelValue,
                                'text-gray-400 dark:text-gray-500':
                                    !props.modelValue
                            },
                            'placeholder:text-gray-400 dark:placeholder:text-gray-500',
                            'py-1.5 px-3',
                            //Shape
                            'rounded-none',
                            // Transitions
                            'transition',
                            'duration-200',
                            // States
                            'focus:outline-none focus:shadow-none',
                            // Misc
                            'relative',
                            'cursor-pointer',
                            'overflow-hidden overflow-ellipsis',
                            'whitespace-nowrap',
                            'appearance-none'
                        ]
                    })
                },
                filterrowitems: {
                    class: 'py-1 list-none m-0'
                },
                filterrowitem: ({ context }) => ({
                    class: [
                        // Font
                        'sm:text-sm',
                        'leading-none',
                        {
                            'font-normal': !(
                                context != null && context.highlighted
                            ),
                            'font-bold':
                                context == null ? void 0 : context.highlighted
                        },
                        // Position
                        'relative',
                        // Shape
                        'border-0',
                        'rounded-none',
                        // Spacing
                        'm-0',
                        'py-2 px-4',
                        // Color
                        {
                            'text-gray-700 dark:text-white/80': !(
                                context != null && context.highlighted
                            )
                        },
                        {
                            'bg-gray-0 dark:bg-gray-800 text-gray-700 dark:text-white/80':
                                !(context != null && context.highlighted)
                        },
                        {
                            'bg-primary-500 dark:bg-primary-400 text-white dark:text-gray-700':
                                context == null ? void 0 : context.highlighted
                        },
                        //States
                        'hover:bg-primary-500 dark:hover:bg-primary-400 hover:text-white dark:hover:text-gray-700',
                        'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
                        // Transitions
                        'transition-shadow',
                        'duration-200',
                        // Misc
                        'cursor-pointer',
                        'overflow-hidden',
                        'whitespace-nowrap'
                    ]
                }),
                filteroperator: {
                    class: [
                        'p-4',
                        'border-b border-solid',
                        'rounded-t-md',
                        'text-gray-700 dark:text-white/80',
                        'border-gray-200 dark:border-gray-700'
                    ]
                },
                filteroperatordropdown: {
                    root: ({ state }) => ({
                        class: [
                            // Display and Position
                            'flex',
                            'relative',
                            // Shape
                            'w-full',
                            'rounded-md',
                            'shadow-sm',
                            // Color and Background
                            'text-gray-800 dark:text-white/80',
                            'placeholder:text-gray-400 dark:placeholder:text-gray-500',
                            'bg-gray-0 dark:bg-gray-900',
                            {
                                'ring-1 ring-inset ring-gray-300 dark:ring-gray-700':
                                    !state.focused
                            },
                            // Transitions
                            'transition-all',
                            'duration-200',
                            // States
                            {
                                'outline-none outline-offset-0 ring-2 ring-primary-500 dark:ring-primary-400':
                                    state.focused
                            },
                            // Misc
                            'cursor-default',
                            'select-none'
                        ]
                    }),
                    input: {
                        class: [
                            'font-sans',
                            'leading-6',
                            'sm:text-sm',
                            'block',
                            'flex-auto',
                            'bg-transparent',
                            'border-0',
                            'text-gray-800 dark:text-white/80',
                            'placeholder:text-gray-400 dark:placeholder:text-gray-500',
                            'py-1.5 px-3',
                            'rounded-none',
                            'transition',
                            'duration-200',
                            'focus:outline-none focus:shadow-none',
                            'relative',
                            'cursor-pointer',
                            'overflow-hidden overflow-ellipsis',
                            'whitespace-nowrap',
                            'appearance-none'
                        ]
                    },
                    trigger: {
                        class: [
                            'flex items-center justify-center',
                            'shrink-0',
                            'bg-transparent',
                            'text-gray-500',
                            'w-12',
                            'rounded-tr-md',
                            'rounded-br-md'
                        ]
                    },
                    panel: {
                        class: [
                            'absolute top-0 left-0',
                            'mt-2',
                            'border-0',
                            'rounded-md',
                            'shadow-md',
                            'min-w-[12.5rem]',
                            'bg-gray-0 dark:bg-gray-800',
                            'text-gray-800 dark:text-white/80',
                            'ring-1 ring-inset ring-gray-300 dark:ring-gray-700'
                        ]
                    },
                    list: {
                        class: 'py-1 list-none m-0'
                    },
                    item: ({ context }) => ({
                        class: [
                            // Font
                            'sm:text-sm',
                            'leading-none',
                            {
                                'font-normal': !(
                                    context != null && context.highlighted
                                ),
                                'font-bold':
                                    context == null
                                        ? void 0
                                        : context.highlighted
                            },
                            // Position
                            'relative',
                            // Shape
                            'border-0',
                            'rounded-none',
                            // Spacing
                            'm-0',
                            'py-2 px-4',
                            // Color
                            {
                                'text-gray-700 dark:text-white/80': !(
                                    context != null && context.highlighted
                                )
                            },
                            {
                                'bg-gray-0 dark:bg-gray-800 text-gray-700 dark:text-white/80':
                                    !(context != null && context.highlighted)
                            },
                            {
                                'bg-primary-500 dark:bg-primary-400 text-white dark:text-gray-700':
                                    context == null
                                        ? void 0
                                        : context.highlighted
                            },
                            //States
                            'hover:bg-primary-500 dark:hover:bg-primary-400 hover:text-white dark:hover:text-gray-700',
                            // Transitions
                            'transition-shadow',
                            'duration-200',
                            // Misc
                            'cursor-pointer',
                            'overflow-hidden',
                            'whitespace-nowrap'
                        ]
                    })
                },
                filterconstraint: {
                    class: [
                        'p-4',
                        'border-b border-solid',
                        'border-gray-200 dark:border-gray-700'
                    ]
                },
                filteraddrule: {
                    class: 'pt-4 pb-2 px-4'
                },
                filteraddrulebutton: {
                    root: {
                        class: [
                            'relative',
                            'items-center inline-flex text-center align-bottom justify-center',
                            'text-sm px-2.5 py-1.5 min-w-[2rem] w-full',
                            'rounded-md',
                            'bg-transparent border-transparent',
                            'text-primary-500 dark:text-primary-400',
                            'hover:bg-primary-300/20',
                            'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-offset-current',
                            'focus:ring-primary-500 dark:focus:ring-primary-400',
                            'transition duration-200 ease-in-out',
                            'cursor-pointer overflow-hidden select-none'
                        ]
                    },
                    label: {
                        class: 'flex-auto grow-0'
                    },
                    icon: {
                        class: 'mr-2'
                    }
                },
                filterremovebutton: {
                    root: {
                        class: [
                            'relative',
                            'items-center inline-flex text-center align-bottom justify-center',
                            'text-sm px-2.5 py-1.5 min-w-[2rem] w-full mt-2',
                            'rounded-md',
                            'bg-transparent border-transparent',
                            'text-red-500 dark:text-red-400',
                            'hover:bg-red-300/20',
                            'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-offset-current',
                            'focus:ring-red-500 dark:focus:ring-red-400',
                            'transition duration-200 ease-in-out',
                            'cursor-pointer overflow-hidden select-none'
                        ]
                    },
                    label: {
                        class: 'flex-auto grow-0'
                    },
                    icon: {
                        class: 'mr-2'
                    }
                },
                filterbuttonbar: {
                    class: ['flex items-center justify-between', 'py-4 px-4']
                },
                filterclearbutton: {
                    root: {
                        class: [
                            'relative',
                            'items-center inline-flex text-center align-bottom justify-center',
                            'text-sm px-2.5 py-1.5 min-w-[2rem]',
                            'rounded-md shadow-sm border-0',
                            'text-primary-500 ring-1 ring-primary-500 hover:bg-primary-300/20',
                            'hover:bg-primary-300/20',
                            'focus:ring-primary-500 dark:focus:ring-primary-400',
                            'transition duration-200 ease-in-out',
                            'cursor-pointer overflow-hidden select-none'
                        ]
                    }
                },
                filterapplybutton: {
                    root: {
                        class: [
                            'relative',
                            'items-center inline-flex text-center align-bottom justify-center',
                            'text-sm px-2.5 py-1.5 min-w-[2rem]',
                            'rounded-md border-0',
                            'text-white dark:text-gray-900',
                            'bg-primary-500 dark:bg-primary-400',
                            'ring-1 ring-primary-500 dark:ring-primary-400',
                            'hover:bg-primary-600 dark:hover:bg-primary-300 hover:ring-primary-600 dark:hover:ring-primary-300',
                            'focus:ring-primary-500 dark:focus:ring-primary-400',
                            'transition duration-200 ease-in-out',
                            'cursor-pointer overflow-hidden select-none'
                        ]
                    }
                },
                filtermenubutton: ({ context }) => ({
                    class: [
                        'relative',
                        // Flex & Alignment
                        'inline-flex items-center justify-center',
                        // Size
                        'w-8 h-8',
                        // Spacing
                        'ml-2',
                        // Shape
                        'rounded-full',
                        // Color
                        { 'bg-primary-50 text-primary-700': context.active },
                        'dark:text-white/70 dark:hover:text-white/80 dark:bg-gray-800',
                        // States
                        'hover:text-gray-700 hover:bg-gray-300/20',
                        'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-primary-400',
                        // Transition
                        'transition duration-200',
                        // Misc
                        'cursor-pointer no-underline overflow-hidden'
                    ]
                }),
                headerfilterclearbutton: ({ context }) => ({
                    class: [
                        'relative',
                        // Flex & Alignment
                        'inline-flex items-center justify-center',
                        'text-left',
                        // Shape
                        'border-none',
                        // Spacing
                        'm-0 p-0 ml-2',
                        // Color
                        'bg-transparent',
                        // Misc
                        'cursor-pointer no-underline overflow-hidden select-none',
                        {
                            invisible: !context.hidden
                        }
                    ]
                }),
                rowtoggler: {
                    class: [
                        'relative',
                        'inline-flex items-center justify-center',
                        'text-left',
                        'm-0 p-0',
                        'w-8 h-8',
                        'border-0 rounded-full',
                        'text-gray-500 dark:text-white/70',
                        'bg-transparent',
                        'focus-visible:outline-none focus-visible:outline-offset-0',
                        'focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
                        'transition duration-200',
                        'overflow-hidden',
                        'cursor-pointer select-none'
                    ]
                },
                columnresizer: {
                    class: [
                        'block',
                        'absolute top-0 right-0',
                        'w-2 h-full',
                        'm-0 p-0',
                        'border border-transparent',
                        'cursor-col-resize'
                    ]
                },
                rowreordericon: {
                    class: 'cursor-move'
                },
                roweditorinitbutton: {
                    class: [
                        'relative',
                        'inline-flex items-center justify-center',
                        'text-left',
                        'w-8 h-8',
                        'border-0 rounded-full',
                        'text-gray-700 dark:text-white/70',
                        'border-transparent',
                        'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-primary-400',
                        'hover:text-gray-700 hover:bg-gray-300/20',
                        'transition duration-200',
                        'overflow-hidden',
                        'cursor-pointer select-none'
                    ]
                },
                roweditorsavebutton: {
                    class: [
                        'relative',
                        'inline-flex items-center justify-center',
                        'text-left',
                        'w-8 h-8',
                        'border-0 rounded-full',
                        'text-gray-700 dark:text-white/70',
                        'border-transparent',
                        'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-primary-400',
                        'hover:text-gray-700 hover:bg-gray-300/20',
                        'transition duration-200',
                        'overflow-hidden',
                        'cursor-pointer select-none'
                    ]
                },
                roweditorcancelbutton: {
                    class: [
                        'relative',
                        'inline-flex items-center justify-center',
                        'text-left',
                        'w-8 h-8',
                        'border-0 rounded-full',
                        'text-gray-700 dark:text-white/70',
                        'border-transparent',
                        'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-inset focus:ring-primary-500 dark:focus:ring-primary-400',
                        'hover:text-gray-700 hover:bg-gray-300/20',
                        'transition duration-200',
                        'overflow-hidden',
                        'cursor-pointer select-none'
                    ]
                },
                radiobuttonwrapper: {
                    class: [
                        'relative',
                        'inline-flex align-bottom',
                        'w-4 h-4',
                        'cursor-pointer select-none'
                    ]
                },
                rowRadioButton: {
                    root: {
                        class: [
                            'relative',
                            'inline-flex',
                            'align-bottom',
                            'w-4 h-4',
                            'cursor-default',
                            'select-none'
                        ]
                    },
                    box: ({ props }) => ({
                        class: [
                            // Flexbox
                            'flex justify-center items-center',
                            // Size
                            'w-4 h-4',
                            'text-sm',
                            'font-medium',
                            // Shape
                            'border-2',
                            'rounded-full',
                            // Transition
                            'transition duration-200 ease-in-out',
                            // Colors
                            {
                                'text-gray-700 dark:text-white/80':
                                    !props.modelValue,
                                'bg-gray-0 dark:bg-gray-900': !props.modelValue,
                                'border-gray-300 dark:border-gray-700':
                                    !props.modelValue,
                                'border-primary-500 dark:border-primary-400':
                                    props.modelValue
                            },
                            // States
                            {
                                'outline-none outline-offset-0':
                                    !props.disabled,
                                'peer-focus-visible:ring-2 peer-focus-visible:ring-offset-2 peer-focus-visible:ring-offset-gray-0 dark:focus-visible:ring-offset-gray-800 peer-focus-visible:ring-primary-500 dark:peer-focus-visible:ring-primary-400':
                                    !props.disabled,
                                'opacity-60 cursor-default': props.disabled
                            }
                        ]
                    }),
                    input: {
                        class: [
                            'peer',
                            'w-full ',
                            'h-full',
                            'absolute',
                            'top-0 left-0',
                            'z-10',
                            'p-0',
                            'm-0',
                            'opacity-0',
                            'rounded-md',
                            'outline-none',
                            'border-2 border-gray-300 dark:border-gray-700',
                            'appearance-none',
                            'cursor-default'
                        ]
                    },
                    icon: {
                        class: 'hidden'
                    }
                },
                headerCheckbox: {
                    root: {
                        class: [
                            'relative',
                            'inline-flex',
                            'align-bottom',
                            'w-4',
                            'h-4',
                            'cursor-default',
                            'select-none'
                        ]
                    },
                    box: ({ props, context }) => ({
                        class: [
                            // Alignment
                            'flex',
                            'items-center',
                            'justify-center',
                            // Size
                            'w-4',
                            'h-4',
                            // Shape
                            'rounded',
                            'border',
                            // Colors
                            'text-gray-600',
                            {
                                'border-gray-300 bg-gray-0 dark:border-gray-700 dark:bg-gray-900':
                                    !context.checked,
                                'border-primary-500 bg-primary-500 dark:border-primary-400 dark:bg-primary-400':
                                    context.checked
                            },
                            {
                                'ring-2 ring-primary-500 dark:ring-primary-400':
                                    !props.disabled && context.focused,
                                'cursor-default opacity-60': props.disabled
                            },
                            // States
                            {
                                'peer-focus-visible:ring-2 peer-focus-visible:ring-primary-500 dark:peer-focus-visible:ring-primary-400':
                                    !props.disabled,
                                'cursor-default opacity-60': props.disabled
                            },
                            // Transitions
                            'transition-colors',
                            'duration-200'
                        ]
                    }),
                    input: {
                        class: [
                            'peer',
                            'w-full ',
                            'h-full',
                            'absolute',
                            'top-0 left-0',
                            'z-10',
                            'p-0',
                            'm-0',
                            'rounded',
                            'border',
                            'opacity-0',
                            'rounded-md',
                            'outline-none',
                            'border-2 border-gray-300 dark:border-gray-700',
                            'appearance-none'
                        ]
                    },
                    icon: {
                        class: [
                            'text-normal',
                            'w-3',
                            'h-3',
                            'text-white dark:text-gray-900',
                            'transition-all',
                            'duration-200'
                        ]
                    }
                },
                rowCheckbox: {
                    root: {
                        class: [
                            'relative',
                            'inline-flex',
                            'align-bottom',
                            'w-4',
                            'h-4',
                            'cursor-default',
                            'select-none'
                        ]
                    },
                    box: ({ props, context }) => ({
                        class: [
                            // Alignment
                            'flex',
                            'items-center',
                            'justify-center',
                            // Size
                            'w-4',
                            'h-4',
                            // Shape
                            'rounded',
                            'border',
                            // Colors
                            'text-gray-600',
                            {
                                'border-gray-300 bg-gray-0 dark:border-gray-700 dark:bg-gray-900':
                                    !context.checked,
                                'border-primary-500 bg-primary-500 dark:border-primary-400 dark:bg-primary-400':
                                    context.checked
                            },
                            {
                                'ring-2 ring-primary-500 dark:ring-primary-400':
                                    !props.disabled && context.focused,
                                'cursor-default opacity-60': props.disabled
                            },
                            // States
                            {
                                'peer-focus-visible:ring-2 peer-focus-visible:ring-primary-500 dark:peer-focus-visible:ring-primary-400':
                                    !props.disabled,
                                'cursor-default opacity-60': props.disabled
                            },
                            // Transitions
                            'transition-colors',
                            'duration-200'
                        ]
                    }),
                    input: {
                        class: [
                            'peer',
                            'w-full ',
                            'h-full',
                            'absolute',
                            'top-0 left-0',
                            'z-10',
                            'p-0',
                            'm-0',
                            'rounded',
                            'border',
                            'opacity-0',
                            'rounded-md',
                            'outline-none',
                            'border-2 border-gray-300 dark:border-gray-700',
                            'appearance-none'
                        ]
                    },
                    icon: {
                        class: [
                            'text-normal',
                            'w-3',
                            'h-3',
                            'text-white dark:text-gray-900',
                            'transition-all',
                            'duration-200'
                        ]
                    }
                },
                transition: {
                    enterFromClass: 'opacity-0 scale-y-[0.8]',
                    enterActiveClass:
                        'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
                    leaveActiveClass:
                        'transition-opacity duration-100 ease-linear',
                    leaveToClass: 'opacity-0'
                }
            },
            bodyrow: ({ context, props }) => ({
                class: [
                    // Color
                    'dark:text-white/80',
                    {
                        'bg-gray-100 dark:bg-gray-500/30':
                            context.selected && context.stripedRows
                    },
                    {
                        'bg-gray-50 dark:bg-gray-500/30':
                            context.selected && !context.stripedRows
                    },
                    {
                        'bg-gray-0 text-gray-600 dark:bg-gray-800':
                            !context.selected
                    },
                    { 'bg-gray-0 dark:bg-gray-800': props.frozenRow },
                    {
                        'odd:bg-gray-0 odd:text-gray-600 dark:odd:bg-gray-800 even:bg-gray-50 even:text-gray-600 dark:even:bg-gray-900/60':
                            true || (context.stripedRows && !context.selected)
                    },
                    // State
                    {
                        'focus:outline-none focus:outline-offset-0 focus:ring-2 focus:ring-primary-500 ring-inset dark:focus:ring-primary-400':
                            props.selectionMode
                    },
                    {
                        'hover:bg-gray-300/20 hover:text-gray-600':
                            props.selectionMode && !context.selected
                    },
                    // Transition
                    {
                        'transition duration-200':
                            (props.selectionMode && !context.selected) ||
                            props.rowHover
                    },
                    // Misc
                    { 'cursor-pointer': props.selectionMode }
                ]
            }),
            rowexpansion: {
                class: 'bg-gray-0 dark:bg-gray-800 text-gray-600 dark:text-white/80'
            },
            rowgroupheader: {
                class: [
                    'sticky z-20',
                    'bg-gray-0 text-gray-600 dark:text-white/70',
                    'dark:bg-gray-800'
                ]
            },
            rowgroupfooter: {
                class: [
                    'sticky z-20',
                    'bg-gray-0 text-gray-600 dark:text-white/70',
                    'dark:bg-gray-800'
                ]
            },
            rowgrouptoggler: {
                class: [
                    'relative',
                    'inline-flex items-center justify-center',
                    'text-left',
                    'm-0 p-0',
                    'w-8 h-8',
                    'border-0 rounded-full',
                    'text-gray-500 dark:text-white/70',
                    'bg-transparent',
                    'focus-visible:outline-none focus-visible:outline-offset-0',
                    'focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
                    'transition duration-200',
                    'overflow-hidden',
                    'cursor-pointer select-none'
                ]
            },
            rowgrouptogglericon: {
                class: 'inline-block w-4 h-4'
            },
            resizehelper: {
                class: 'absolute hidden w-[2px] z-20 bg-primary-500 dark:bg-primary-400'
            }
        },
        //UPLOAD
        fileupload: {
            input: {
                class: 'hidden'
            },
            buttonbar: {
                class: [
                    'flex flex-wrap',
                    'bg-gray-50 dark:bg-gray-800 p-5 border border-solid border-gray-300 dark:border-primary-900/40 text-gray-700 dark:text-white/80 rounded-tr-lg rounded-tl-lg gap-2 border-b-0'
                ]
            },
            chooseButton: {
                class: [
                    'flex cursor-pointer text-white bg-primary-500 border border-primary-500 p-3 px-5 text-base',
                    'overflow-hidden relative'
                ]
            },
            chooseIcon: {
                class: 'mr-2 inline-block'
            },
            chooseButtonLabel: {
                class: 'flex-1 font-bold'
            },
            uploadbutton: {
                icon: {
                    class: 'mr-2'
                }
            },
            cancelbutton: {
                icon: {
                    class: 'mr-2'
                }
            },
            content: {
                class: [
                    'relative',
                    'bg-white dark:bg-gray-900 p-8 border border-gray-300 dark:border-primary-900/40 text-gray-700 dark:text-white/80 rounded-b-lg'
                ]
            },
            file: {
                class: [
                    'flex items-center flex-wrap',
                    'p-4 border border-gray-300 dark:border-primary-900/40 rounded gap-2 mb-2',
                    'last:mb-0'
                ]
            },
            thumbnail: {
                class: 'shrink-0'
            },
            fileName: {
                class: 'mb-2'
            },
            fileSize: {
                class: 'mr-2'
            },
            uploadicon: {
                class: 'mr-2'
            }
        },
        textarea: {
            root: ({ context }) => ({
                class: [
                    'm-0',
                    'font-sans !text-[16px] text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 p-3 border border-gray-300 dark:border-primary-900/40 transition-colors duration-200 appearance-none rounded-none',
                    'hover:border-primary-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)]',
                    {
                        'opacity-60 select-none pointer-events-none cursor-default':
                            context.disabled
                    }
                ]
            })
        }
    },
    {
        mergeSections: true,
        mergeProps: false
    }
)

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.use(PrimeVue, {
        ripple: true,
        unstyled: true,
        pt: CustomTailwind
    })
    nuxtApp.vueApp.use(ToastService)
    nuxtApp.vueApp.use(DialogService)
    nuxtApp.vueApp.use(ConfirmationService)
    nuxtApp.vueApp.directive('ripple', Ripple)
    nuxtApp.vueApp.directive('tooltip', Tooltip)
})
