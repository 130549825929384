import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin(nuxtApp => {
    if (!process.client) return

    nuxtApp.vueApp.config.errorHandler = () => {
        nuxtApp.vueApp.config.errorHandler = error => {
            // Do nothing, but this prevents calling the Nuxt error handler
            console.log(error)
        }

        // // Also possible
        // nuxtApp.hook('vue:error', (error, instance, info) => {
        //     console.log(error)
        //     return false
        // })
    }
})
